<template>
  <div id="app">
    <rozo-with-config
        :config="config"/>
  </div>
</template>

<script>
import RozoWithConfig from '@synphonyte/v-rozo/src/components/RozoWithConfig'


const config = require( `./assets/rozo-config.json` );

const sortedImages = [];
for ( let i = 0; i < config.rowCount * config.framesPerRow; ++i ) {
  sortedImages.push( require( `./assets/${ i.toString().padStart( 6, '0' ) }.jpg` ) )
}

export default {
  name: 'App',

  components: {
    RozoWithConfig
  },

  data() {
    return {
      config: {
        ...config,
        images: sortedImages,
      }
    }
  },

  mounted() {
    document.title = config.title;
  }
}
</script>

<style>
body, html {
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  overflow: hidden;
}

#app {
  width: 100vw;
  height: 100vh;
}
</style>
